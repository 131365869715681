import $api from "../http"
import {AxiosResponse} from "axios";
import {AuthResponse, ChangePasswordData, RestorePasswordData, RestorePasswordRequest} from "../models/Auth";
import {CreateUserResponse} from "../models/CreateResponse";
import {LoginCredentials, RegistrationCredentials} from "../models/LoginRegister";

export default class AuthService {
    static async login(creds: LoginCredentials): Promise<AxiosResponse<AuthResponse>> {
        return $api.post<AuthResponse>("/v1/auth/login", creds)
    }

    static async registration(creds: RegistrationCredentials): Promise<AxiosResponse<CreateUserResponse>> {
        return $api.post<CreateUserResponse>("/v1/meme-devs", creds)
    }

    static async verifyAccount(id: number, token: string) {
        return await $api.post(`/v1/meme-devs/${id}/verify/${token}`)
    }

    static async sendRestorePasswordEmail(data: RestorePasswordRequest) {
        return await $api.post(`/v1/auth/restore-password-email`, data)
    }

    static async restorePassword(data: RestorePasswordData) {
        return await $api.patch(`/v1/auth/restore-password`, data)
    }

    static async changePassword(data: ChangePasswordData) {
        return await $api.patch(`/v1/auth/change-password`, data)
    }

    static async sendVerificationEmail() {
        return await $api.post(`/v1/meme-devs/verify`)
    }

    static async logout(fromAll: boolean): Promise<void> {
        return $api.post(`/v1/auth/logout${fromAll ? "?from-all=true" : ""}`)
    }

}