import {ITitle} from "../../../../../models/Memes";
import {makeAutoObservable, toJS} from "mobx";

export interface Size {width: number, height: number}
interface TextArea extends Size {left: number, bottom: number}

export class TitleStore {
    private titleIdSeq: number = 0; // for react 'key' property
    titleIds: number[] = [];
    titles: ITitle[] = [];
    //title text if title.text.length === 0
    placeHolder: string = "";
    //textarea to fit title
    textAreas: TextArea[] = [];
    //container inside which textarea elements are located
    container: Size = {width: 0, height: 0};

    constructor() {
        makeAutoObservable(this);
    }

    reset = () => {
        this.setAllTitles([], {width: 0, height: 0});
        this.titleIdSeq = 0;
    }

    getTitles = () => {
        return toJS(this.titles);
    }

    setTitle = (index: number, title: ITitle) => {
        if (index > this.titles.length - 1) {
            return;
        }
        // console.log("got:", title.x_ratio, title.y_ratio, this.textAreas[index].left, this.textAreas[index].bottom);
        const {corTitle, corTextArea} = this.getCorrectedTitle(title);
        this.titles[index] = corTitle;
        this.textAreas[index] = corTextArea;
        // console.log("calc:", corTitle.x_ratio, corTitle.y_ratio, this.textAreas[index].left, this.textAreas[index].bottom);
        // console.log("fontSize:", corTitle.font_size);
    }

    setText = (index: number, text: string) => {
        if (index > this.titles.length - 1) {
            return;
        }
        this.setTitle(index, {...this.titles[index], text: text});
    }

    setPlaceholder = (text: string) => {
        this.placeHolder = text;
        for (let i = 0; i < this.titles.length; i++){
            this.setTitle(i, {...this.titles[i], text: ""});
        }
    }

    setPosition = (index: number, xRatio: number, yRatio: number) => {
        if (index > this.titles.length - 1) {
            return;
        }
        this.setTitle(index, {...this.titles[index], x_ratio: xRatio, y_ratio: yRatio});
    }

    setFontSize = (index: number, fontSize: number) => {
        if (index > this.titles.length - 1) {
            return;
        }
        this.setTitle(index, {...this.titles[index], font_size: fontSize});
    }

    setFont = (index: number, font: string) => {
        if (index > this.titles.length - 1) {
            return;
        }
        this.setTitle(index, {...this.titles[index], font: font});
    }

    setColor = (index: number, color: number) => {
        if (index > this.titles.length - 1) {
            return;
        }
        this.titles[index].text_color = color;
        // this.setTitle(index, this.titles[index]);
    }

    append = (title: ITitle) => {
        this.titleIdSeq++;
        this.titleIds.push(this.titleIdSeq);
        const {corTitle, corTextArea} = this.getCorrectedTitle(title);
        this.titles.push(corTitle);
        this.textAreas.push(corTextArea);
    }

    appendDefault = (font: string) => {
        this.append({
            angle: 0,
            font: font,
            font_size: 30,
            text: "",
            text_color: 4278190080,
            x_ratio: 0.5,
            y_ratio: 0.5
        })
    }

    drop = (index: number) => {
        if (index > this.titles.length - 1) {
            return;
        }
        this.titleIds.splice(index, 1);
        this.titles.splice(index, 1);
        this.textAreas.splice(index, 1);
    }

    setAllTitles = (titles: ITitle[], container?: Size, placeHolder?: string) => {
        this.titles = [];
        this.titleIds = [];
        this.textAreas = [];
        if (placeHolder) {
            this.placeHolder = placeHolder;
        }
        if (container) {
            this.container = container;
        }
        for (let i = 0; i < titles.length; i++) {
            this.append(titles[i]);
        }
    }

    cloneTitle = (index: number) => {
        if (index > this.titles.length - 1) {
            throw new Error(`No title with index ${index}`);
        }
        const title = this.titles[index];
        return TitleStore.cloneTitle(title);
    }
    
    static cloneTitle = (title: ITitle) => {
        return {angle: title.angle, font: title.font, font_size: title.font_size, text: title.text, text_color: title.text_color, x_ratio: title.x_ratio, y_ratio: title.y_ratio};
    }
    

    private getCorrectedTitle(title: ITitle) {
        const {fontSize, textAreaSize} = getCorrectedFontAndTextAreaSize(title, this.placeHolder, this.container);
        const {left, bottom, xRatio, yRatio} = getTextAreaPosition(title, this.container, textAreaSize);

        const corTextArea: TextArea = {
            left: left,
            bottom: bottom,
            width: textAreaSize.width,
            height: textAreaSize.height,
        }

        return {
            corTitle: {...title, font_size: fontSize, x_ratio: xRatio, y_ratio: yRatio},
            corTextArea: corTextArea
        };
    }
}

// Calculate title width and height in pixels
export function getTextAreaSize(title: ITitle, placeholderText: string, container: Size, strokeSize?: string) {
    let text: string;
    if (title.text.length === 0) {
        text = placeholderText;
    } else {
        text = title.text;
    }

    // create dummy element for size calculations
    const tempElement = document.createElement('div');
    tempElement.style.position = 'fixed';
    tempElement.style.width = 'auto';
    tempElement.style.height = 'auto';
    if (strokeSize) {
        tempElement.style.webkitTextStroke = strokeSize
        tempElement.style.webkitTextStrokeColor = "black"
    }
    // 'pre' is necessary to save 2 or more spaces
    tempElement.style.whiteSpace = 'pre';
    tempElement.style.visibility = 'hidden';
    tempElement.style.fontFamily = title.font;
    tempElement.style.fontSize = `${title.font_size*container.height}px`;

    // split title into lines and calculate total height and max line width
    // split is necessary since div element don't support line breaks
    const lines = text.split('\n');
    let totalHeight = 0;

    //calculate max line width and total height
    const maxWidth = lines.reduce((max, line) => {
        if (line.length === 0) {
            // any character to calculate the height correctly, otherwise line height will be 0
            line = "i";
        }
        tempElement.textContent = line;
        document.body.appendChild(tempElement);
        const width = tempElement.offsetWidth;

        totalHeight += tempElement.clientHeight;
        document.body.removeChild(tempElement);
        return Math.max(max, width);
    }, 0);

    tempElement.remove();

    if (title.text === "पाठ का परीक्षण\n   एक दो\n") {
        console.log("titleX:", title.x_ratio)
        console.log("titleY:", title.y_ratio)
        console.log("width:", maxWidth)
        console.log("height:", totalHeight)
    }

    return {width: maxWidth, height: totalHeight};
}

// Calculate corrected title position in pixels
export function getTextAreaPosition(title: ITitle, container: Size, textAreaSize: Size) {
    const x = title.x_ratio * container.width;
    const y = (1 - title.y_ratio) * container.height;
    let left = x - textAreaSize.width/2;
    let bottom = y - textAreaSize.height/2;

    if (left < 0) {
        left = 0;
    } else if (x + textAreaSize.width/2 > container.width + 2) {
        left = container.width - textAreaSize.width;
    }

    if (bottom < 0) {
        bottom = 0;
    } else if (y + textAreaSize.height/2 > container.height) {
        bottom = container.height - textAreaSize.height;
    }

    const xRatio = (left + textAreaSize.width/2)/container.width;
    const yRatio = (container.height - bottom - textAreaSize.height/2)/container.height;

    return {left, bottom, xRatio, yRatio};
}

// Calculate corrected font size and title width and height, if title don't fit in container
export function getCorrectedFontAndTextAreaSize(title: ITitle, placeHolderText: string, container: Size, strokeSize?: string) {
    if (container.width === 0 || container.height === 0 || title.font_size <= 0) {
        return {fontSize: 0, textAreaSize: {width: 0, height: 0}};
    }

    let fontSize = title.font_size;
    let textAreaSize = getTextAreaSize(title, placeHolderText, container, strokeSize);
    let oversize = Math.max(textAreaSize.width/container.width, textAreaSize.height/container.height);
    while (oversize > 1) {
        const resizeRate = 0.02 + oversize;
        fontSize = fontSize/resizeRate;
        textAreaSize = getTextAreaSize({...title, font_size: fontSize}, placeHolderText, container, strokeSize);
        oversize = Math.max(textAreaSize.width/container.width, textAreaSize.height/container.height);
    }

    return {fontSize: fontSize, textAreaSize: textAreaSize};
}