// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { fetchAndActivate, getRemoteConfig, getValue } from "firebase/remote-config";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FB_API_KEY,
    authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FB_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FB_ID,
    measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 1000;
export const rcPromoList: RemoteCfg[] = [];
export let rcPromo1: RemoteCfg = {
    url: "",
    mode: 0,
    is_enabled: false,
    auth_type: "for-all",
};
export let rcPromo2: RemoteCfg = {
    url: "",
    mode: 0,
    is_enabled: false,
    auth_type: "for-all",
};
export let rcPromo3: RemoteCfg = {
    url: "",
    mode: 0,
    is_enabled: false,
    auth_type: "for-all",
};
export let rcPromo4: RemoteCfg = {
    url: "",
    mode: 0,
    is_enabled: false,
    auth_type: "for-all",
};
export let rcPromo5: RemoteCfg = {
    url: "",
    mode: 0,
    is_enabled: false,
    auth_type: "for-all",
};
export let rcPromo6: RemoteCfg = {
    url: "",
    mode: 0,
    is_enabled: false,
    auth_type: "for-all",
};
fetchAndActivate(remoteConfig)
    .then(() => {
        let rcpjson = getValue(remoteConfig, "mkt_for_non_auth_users").asString();
        if (rcpjson !== "") {
            rcPromo1 = JSON.parse(rcpjson);
            rcPromo1.auth_type = "for-non-auth";
            rcPromoList.push(rcPromo1);
        }

        rcpjson = getValue(remoteConfig, "mkt_referral_for_friends").asString();
        if (rcpjson !== "") {
            rcPromo2 = JSON.parse(rcpjson);
            rcPromo2.auth_type = "for-auth";
            rcPromoList.push(rcPromo2);
        }

        rcpjson = getValue(remoteConfig, "mkt_for_auth_users").asString();
        if (rcpjson !== "") {
            rcPromo3 = JSON.parse(rcpjson);
            rcPromo3.auth_type = "for-auth";
            rcPromoList.push(rcPromo3);
        }

        rcpjson = getValue(remoteConfig, "mkt_for_everybody").asString();
        if (rcpjson !== "") {
            rcPromo4 = JSON.parse(rcpjson);
            rcPromo4.auth_type = "for-all";
            rcPromoList.push(rcPromo4);
        }

        // rcpjson = getValue(remoteConfig, "test_web").asString();
        // if (rcpjson !== "") {
        //     rcPromo5 = JSON.parse(rcpjson);
        //     rcPromo5.auth_type = "for-non-auth";
        //     rcPromoList.push(rcPromo5);
        // }
        
        // rcpjson = getValue(remoteConfig, "test_web_2").asString();
        // if (rcpjson !== "") {
        //     rcPromo6 = JSON.parse(rcpjson);
        //     rcPromo6.auth_type = "for-auth";
        //     rcPromoList.push(rcPromo6);
        // }
    })
    .catch((err) => {
        console.log(err);
    });


export interface RemoteCfg {
    url: string,
    mode: number,
    is_enabled: boolean,
    auth_type: "for-all" | "for-auth" | "for-non-auth"
}