import cl from "./Btn.module.css";
import close_img from "../../images/ui/close.png";
import React, {CSSProperties} from "react";

interface CloseBtnProps {
    handleClickButton(): void,
    btnStyle?: CSSProperties,
    imgStyle?: CSSProperties,
}

export function CloseBtn({handleClickButton, btnStyle, imgStyle}: CloseBtnProps) {
    return (
        <div className={cl.CloseBtn}
             onClick={handleClickButton}
             style={btnStyle}>
            <img className={cl.CloseImg}
                 style={imgStyle}
                 src={close_img} alt={"close_img"}/>
        </div>
    );
}