import React, {CSSProperties} from "react";
import i18n from "i18next";
import {ServerError, ServerErrorImpl} from "../../views/Error";
import {GetTranslationKey, GetTranslationKeyList, ValidationObject} from "../../models/Error";
import cl from "./MessageModal.module.css";
import {toast} from "react-toastify";

export interface ErrorProps {
    err: Error | ServerError,
}

export interface ErrorModalProps extends ErrorProps {
    visible: boolean,
    setVisible(bool: boolean) : void,
}

export interface ErrorMsgProps extends ErrorProps {
    validationErrProps?: {
        allMessages: boolean,
        validationObj: ValidationObject
    },
}

const errStyle : CSSProperties = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}

export default function ErrorPage({err}: ErrorProps) {
    let msg = err.message
    if (err instanceof ServerErrorImpl && err.status >= 400) {
        msg = i18n.t(GetTranslationKey(msg).key as any);
    } else {
        console.log(err); //API errors are logged in the useFetching() hook
    }

    return (
        <div style={{fontSize: "var(--medium-font-size)", color: "var(--main-color)", ...errStyle}}>{msg}</div>
    );
}

export function ErrorModal({err, visible, setVisible}: ErrorModalProps) {
    let msg = err.message
    if (err instanceof ServerErrorImpl && err.status >= 400) {
        msg = i18n.t(GetTranslationKey(msg).key as any);
    } else {
        console.log(err); //API errors are logged in the useFetching() hook
    }

    const rootClasses = [cl.Modal];
    if (visible) {
        rootClasses.push(cl.active);
    }

    return (
        <div className={rootClasses.join(" ")}
             onMouseDown={() => setVisible(false)}>
            <div className={cl.Container} onMouseDown={(e) => e.stopPropagation()}>
                {msg}
            </div>
        </div>
    );
}

export function ErrorMsg({err, validationErrProps}: ErrorMsgProps) {
    let msg = err.message

    if (err instanceof ServerErrorImpl && err.status >= 400) {
        if (validationErrProps && validationErrProps.allMessages) {
            return (
                <GetValidationListMessages msg={msg} obj={validationErrProps.validationObj}/>
            );
        }
        msg = i18n.t(GetTranslationKey(msg).key as any);
    } else {
        console.log(err); //API errors are logged in the useFetching() hook
    }

    return (
        <div style={{color: "red", ...errStyle}}>{msg}</div>
    );
}

interface GetValidationListMessagesProps {
    msg: string,
    obj: ValidationObject
}

function GetValidationListMessages({msg, obj}: GetValidationListMessagesProps) {
    return (
        <>
            {GetTranslationKeyList(msg, obj)
                .keys.map(key =>
                    <div key={key} style={{color: "red", ...errStyle}}>{i18n.t(key as any)}</div>
                )
            }
        </>
    );
}

// ErrorNotify returns field names which validation has not been passed
export function ErrorNotify({err, validationErrProps}: ErrorMsgProps) : string[] {
    let msg = err.message;
    let field = "unknown";

    if (err instanceof ServerErrorImpl && err.status >= 400) {
        if (validationErrProps && validationErrProps.allMessages) {
            const TrKeys = GetTranslationKeyList(msg, validationErrProps.validationObj)
            TrKeys.keys.forEach(key => {
                const text = i18n.t(key as any);
                toast.error(text, {autoClose: 5000});
            })
            return TrKeys.fieldList;
        }
        const TrKey = GetTranslationKey(msg);
        msg = i18n.t(TrKey.key as any);
        field = TrKey.field
    } else {
        console.log(err); //API errors are logged in the useFetching() hook
    }

    toast.error(msg, {autoClose: 5000});

    return [field]
}