import {TranslationKey} from "../@types/i18next";

export interface ApiError {
    message: string
}

export type ValidationObject = "user" | "meme" | "template"

const validationErrorPattern = "validation error:"

// GetTranslationKey returns a key of a first error message or a default error key
export function GetTranslationKey(message: string, obj?: ValidationObject) {
    const keyList = GetTranslationKeyList(message, obj);

    return keyList.keys.length === 0 ? {key: "ui.defErrMsg", field: "unknown"} : {key: keyList.keys[0], field: keyList.fieldList[0]}
}

// The ValidationObject parameter should be passed if the error text may contain validation errors when creating the object
export function GetTranslationKeyList(message: string, obj?: ValidationObject) {
    if (obj && message.includes(validationErrorPattern)) {
        const validationKeys = getValidationKeys(message, obj);
        if (validationKeys.keys.length !== 0 ) {
            return validationKeys
        } else {
            return {keys: ["ui.defErrMsg"], fieldList: ["unknown"]}
        }
    }
    
    let key = []
    
    switch (message) {
        //400
        case "invalid template form": key = ["apiError._400.invalidTemplateForm"]; break;
        case "invalid birth date": key = ["apiError._400.invalidBirthDate"]; break;
        case "image is missing": key = ["apiError._400.imageMissing"]; break;
        //401
        case "wrong login and/or password": key = ["apiError._401.wrongLoginPwd"]; break;
        case "wrong password": key = ["apiError._401.wrongPwd"]; break;
        //403
        case "account not verified": key = ["apiError._403.accountNotVerified"]; break;
        case "access denied": key = ["apiError._403.accessDenied"]; break;
        //404
        case "not found": key = ["apiError._404.notFound"]; break;
        case "user not found": key = ["apiError._404.userNotFound"]; break;
        case "meme not found": key = ["apiError._404.memeNotFound"]; break;
        case "template not found": key = ["apiError._404.templateNotFound"]; break;
        case "language not supported": key = ["apiError._404.langNotSupported"]; break;
        case "region not supported": key = ["apiError._404.regNotSupported"]; break;
        case "account activation link is invalid or has expired": key = ["apiError._404.activationLink"]; break;
        case "password restore link is invalid or has expired": key = ["apiError._404.pwdRestoreLink"]; break;
        case "unknown report category": key = ["apiError._404.unknownReportCategory"]; break;
        //409
        case "memes are not available for judging": key = ["apiError._409.cantJudge"]; break;
        case "user with given email already exists": key = ["apiError._409.emailExists"]; break;
        case "user with given nickname already exists": key = ["apiError._409.nicknameExists"]; break;
        case "user has already reported": key = ["apiError._409.alreadyReported"]; break;
        //413
        case "request form data size too large": key = ["apiError._413.imgTooLarge"]; break;
        case "string size too large": key = ["apiError._413.stringBodyTooLarge"]; break;
        //422
        case "cannot parse an object": key = ["ui.defErrMsg"]; break;
        case "file type not supported": key = ["apiError._422.imgFileType"]; break;
        case "too stretched image": key = ["apiError._422.imgRatio"]; break;
        //429
        case "creation limit exceeded": key = ["apiError._429.creationLimit"]; break;
        case "email sending limit exceeded": key = ["apiError._429.emailSendingLimit"]; break;

        default: key = ["ui.defErrMsg"]; break;
    }

    return {keys: key, fieldList: ["unknown"]}
}

function getValidationKeys(message: string, obj: ValidationObject) {
    const keys: TranslationKey[] = []
    const msgs = message.split(";");
    const fieldList = msgs.map((msg) => msg.slice("validation error:".length));
    for (let field of fieldList) {
        if (obj === "user") {
            switch (field) {
                case "birth_date": keys.push("apiError._422.userCreation.birthDate"); continue;
                case "email": keys.push("apiError._422.userCreation.email"); continue;
                case "first_name": keys.push("apiError._422.userCreation.firstName"); continue;
                case "last_name": keys.push("apiError._422.userCreation.lastName"); continue;
                case "nick_name": keys.push("apiError._422.userCreation.nickname"); continue;
                case "lang": keys.push("apiError._422.lang"); continue;
                case "reg": keys.push("apiError._422.reg"); continue;
                case "password": keys.push("apiError._422.userCreation.password"); continue;
            }
        }
        if (obj === "meme" || obj === "template") {
            switch (field) {
                case "img_ratio": keys.push("apiError._422.imgRatio"); continue;
                case "lang": keys.push("apiError._422.lang"); continue;
                case "reg": keys.push("apiError._422.reg"); continue;
                case "titles": {
                    if (obj === "meme") {
                        keys.push("apiError._422.memeOrTemplateCreation.titles.memeTitles");
                    } else {
                        keys.push("apiError._422.memeOrTemplateCreation.titles.tmplTitles");
                    }
                    continue;
                }
                case "font_size": keys.push("apiError._422.memeOrTemplateCreation.titles.font_size"); continue;
                case "text": keys.push("apiError._422.memeOrTemplateCreation.titles.text"); continue;
                case "angle": keys.push("apiError._422.memeOrTemplateCreation.titles.angle"); continue;
                case "text_color": keys.push("apiError._422.memeOrTemplateCreation.titles.textColor"); continue;
                case "x_ratio": keys.push("apiError._422.memeOrTemplateCreation.titles.xRatio"); continue;
                case "y_ratio": keys.push("apiError._422.memeOrTemplateCreation.titles.yRatio"); continue;
                case "form": keys.push("apiError._422.memeOrTemplateCreation.templateAddon.form"); continue;
                case "tags": keys.push("apiError._422.memeOrTemplateCreation.templateAddon.tags");
            }
        }
    }

    //if keys.length === 0 means that some validation rule has been overlooked
    return {keys, fieldList}
}