import cl from "./ProfileSettings.module.css"
import {IMemeDev, IMemeDevPrivateInfo, UnblockMemeDevInfo} from "../../../models/MemeDevs";
import React, {ChangeEvent, CSSProperties, useContext, useEffect, useState} from "react";
import ImageLoader from "../../ui/ImageLoader";
import def_avatar_img from "../../images/profile/default-avatar.png";
import camera_img from "../../images/profile/camera_icon.png";
import nickname_img from "../../images/profile/nickname.png";
import amnesty_img from "../../images/profile/amnesty.png";
import email_img from "../../images/profile/email.png";
import password_img from "../../images/profile/password.png";
import warning_img from "../../images/profile/warning.png";
import full_logout_img from "../../images/profile/logout.png";
import {useFetching} from "../../../hooks/useFetching";
import MemeDevService from "../../../services/MemeDevService";
import ErrorPage, {ErrorNotify} from "../../ui/ErrorPage";
import {observer} from "mobx-react-lite";
import {Context} from "../../../index";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import AuthService from "../../../services/AuthService";
import {LoaderPage} from "../../ui/Loader";
import {toast} from "react-toastify";
import {PwdInput, validationErrorStyle} from "../../LoginRegister";
import {ValidationObject} from "../../../models/Error";
import TagService from "../../../services/TagService";
import i18n from "../../../ui-translations/i18n";
import {UnblockTagInfo} from "../../../models/Tag";
import {sendMetric} from "../../../pkg/Metric";

interface ProfileSettingsProps {
    isVisible : boolean,
    setIsVisible : (isVisible: boolean) => void;
}

export const ProfileSettings = observer(({isVisible, setIsVisible}: ProfileSettingsProps) => {
    const {authStore} = useContext(Context);
    const user = authStore.user;
    const { t } = useTranslation();

    const settingsClasses = [cl.SettingsModal];
    if (isVisible) {
        settingsClasses.push(cl.active);
    }

    useEffect(() => {
        sendMetric("goal", "settings");
    }, []);

    if (!user) {
        return <></>
    }
    return (
        <div className={settingsClasses.join(" ")} onClick={() => setIsVisible(false)}>
            <div className={cl.SettingsModalContent} onClick={(e) => e.stopPropagation()}>
                <div className={cl.SettingsTitle}>{t("profile.settings.title")}</div>
                <AvatarSetting user={user}
                               updateUser={authStore.updateUser}/>
                <Setting firstRow={<div className={cl.SettingRowUnclickable}>{user.nick_name}</div>}
                         secondRow={t("profile.settings.nicknameDesc")}
                         iconSrc={nickname_img} iconAlt={"nickname_img"}/>
            </div>
        </div>
    );
})

interface AvatarSettingProps {
    user: IMemeDev,
    updateUser: (user: IMemeDev) => void,
}

function AvatarSetting({user, updateUser}: AvatarSettingProps) {
    const { t } = useTranslation();
    const avatarSizeStyle : CSSProperties = {
        height: "4em",
        width: "4em",
        objectFit: "cover",
        borderRadius: "50%",
    }

    const [updateAvatar, isPending, error] = useFetching(async (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            const response = await MemeDevService.updateMemeDevAvatar({Image: file});
            if (user) {
                updateUser({...user, avatar_url: response.avatar_url});
            }
            event.target.value = "";
            toast.success(t("profile.settings.avatarUpdated"), {autoClose: 1500, style: {zIndex: 99999}})
        }
    })

    useEffect(() => {
        if (error) {
            ErrorNotify({err: error});
        }
    }, [error]);

    return (
        <>
            <div className={cl.Separator}></div>
            <div className={cl.SettingsHeader}>
                <div className={cl.AvatarContainer}>
                    <ImageLoader key={user.avatar_url}
                                 animStyle={avatarSizeStyle}
                                 imgStyle={avatarSizeStyle}
                                 altUrl={def_avatar_img}
                                 url={user.avatar_url} alt="avatar"/>
                    <input className={cl.AvatarInput}
                           type="file"
                           name="image_input"
                           id="image_input"
                           accept="image/png, image/jpeg"
                           onChange={updateAvatar}/>
                    <label htmlFor="image_input"
                           className={cl.AvatarInputLabel}>
                        <img className={cl.AvatarInputImg}
                             src={camera_img}
                             alt={"camera_img"}/>
                    </label>
                </div>
                <div style={{paddingLeft: "2vmin", fontWeight: "500"}}>
                    {user.name ? user.name : user.nick_name}
                </div>
            </div>
        </>
    );
}

function AdvancedSettings() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {authStore, localeStore} = useContext(Context);

    const [isPwdModal, setIsPwdModal] = useState(false);
    const settingsClasses = [cl.SettingsModal];
    if (isPwdModal) {
        settingsClasses.push(cl.active);
    }

    const [blockedMemeDevs, setBlockedMemeDevs] = useState<IMemeDev[]>([]);
    const [blockedTags, setBlockedTags] = useState<string[]>([]);

    const [memeDevPrivateInfo, setMemeDevPrivateInfo] = useState<IMemeDevPrivateInfo>();
    const [fetchMemeDevPrivateInfo, isLoading, fetchError] = useFetching(async () => {
        const info  = await MemeDevService.getMemeDevPrivateInfo();
        setMemeDevPrivateInfo(info);
        localeStore.setMemeLanguage(info.lang.code);
        localeStore.setRegion(info.reg.code);

    });
    useEffect(() => {
        if (authStore.isInited && authStore.isAuth){
            fetchMemeDevPrivateInfo({});
        }
    }, [authStore.isInited])



    const [setReg, isPendingSetReg, errorSetReg]
        = useFetching(async (e: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
            console.log(e.target.value);
            localeStore.setRegion(e.target.value);
            await MemeDevService.updateMemeDevRegion(e.target.value);
        })
    const [setLang, isPendingSetLang, errorSetLang]
        = useFetching(async (e: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
            console.log(e.target.value);
            localeStore.setMemeLanguage(e.target.value);
            await MemeDevService.updateMemeDevLanguage(e.target.value);
        })
    const [fullLogout, isPendingFullLogout, errorFullLogout]
        = useFetching(async () => {
            await authStore.logout(true);
            navigate("/");
        })

    useEffect(() => {
        if (errorSetLang || errorSetReg || errorFullLogout) {
            const err = errorSetLang || errorSetReg || errorFullLogout || new Error(t("ui.defErrMsg"))
            ErrorNotify({err: err})
        }
    }, [errorSetLang, errorSetReg, errorFullLogout]);

    useEffect(() => {
        if (authStore.isInited && !authStore.isAuth) {
            navigate("/");
        }
    }, [authStore.isInited]);

    if (fetchError) {
        return <ErrorPage err={fetchError}/>
    }

    return (
        <>
            {/*<div className={cl.Greetings}>{t("profile.advancedSettings.title")}</div>*/}
            { authStore.isInited && !authStore.isVerified &&
                <div className={cl.Container}>
                    <div className={cl.SectionName}>{t("profile.verification.settings.title")}</div>
                    <AccountVerification/>
                </div>
            }
            {
                <div className={settingsClasses.join(" ")} onMouseDown={() => setIsPwdModal(false)}>
                    <div className={cl.SettingsModalContent} onMouseDown={(e) => e.stopPropagation()}>
                        <ChangePwdForm/>
                    </div>
                </div>
            }
            {/*<div className={cl.Container}>*/}
            {/*    <div className={cl.SectionName}>{t("profile.advancedSettings.locale.title")}</div>*/}
            {/*    <SettingSelector value={localeStore.getMemeLanguage()}*/}
            {/*                     altValue={t("profile.advancedSettings.locale.memesLangName")}*/}
            {/*                     handleValueChange={setLang}*/}
            {/*                     options={localeStore.getLanguageList()}*/}
            {/*                     description={t("profile.advancedSettings.locale.memesLangDesc")}*/}
            {/*                     iconSrc={language_img} iconAlt={"language_img"}/>*/}
            {/*    <SettingSelector value={localeStore.getRegion()}*/}
            {/*                     altValue={t("profile.advancedSettings.locale.regName")}*/}
            {/*                     handleValueChange={setReg}*/}
            {/*                     options={localeStore.getRegionList()}*/}
            {/*                     description={t("profile.advancedSettings.locale.regDesc")}*/}
            {/*                     iconSrc={region_img} iconAlt={"region_img"}/>*/}
            {/*</div>*/}
            <div className={cl.Container}>
                <div className={cl.SectionName}>{t("profile.advancedSettings.security.title")}</div>
                <Setting firstRow={<div className={cl.SettingRowUnclickable}>{memeDevPrivateInfo?.email}</div>}
                         firstRowAlt={t("profile.advancedSettings.security.emailName")}
                         secondRow={t("profile.advancedSettings.security.emailDesc")}
                         iconSrc={email_img} iconAlt={"email_img"}/>
                <Setting firstRow={<div className={cl.SettingRowClickable}
                                        onClick={() => setIsPwdModal(true)}>{t("profile.advancedSettings.security.pwdName")}</div>}
                         secondRow={t("profile.advancedSettings.security.changePwd")}
                         iconSrc={password_img} iconAlt={"password_img"}/>
                <Setting firstRow={<div className={cl.SettingRowClickable}
                                        onClick={() => fullLogout({})}>{t("profile.advancedSettings.security.fullLogoutName")}</div>}
                         secondRow={t("profile.advancedSettings.security.fullLogoutDesc")}
                         iconSrc={full_logout_img} iconAlt={"full_logout_img"}/>
            </div>
            <div className={cl.Container}>
                <div className={cl.SectionName}>{t("profile.advancedSettings.blackList.title")}</div>
                <Setting firstRow={<BlockedMemeDevsTitle blockedMemeDevs={blockedMemeDevs}
                                                         setBlockedMemeDevs={setBlockedMemeDevs}/>}
                         secondRow={<BlockedMemeDevs blockedMemeDevs={blockedMemeDevs}
                                                     setBlockedMemeDevs={setBlockedMemeDevs}/>}/>
                <Setting firstRow={<BlockedTagsTitle blockedTags={blockedTags}
                                                     setBlockedTags={setBlockedTags}/>}
                         secondRow={<BlockedTags blockedTags={blockedTags}
                                                 setBlockedTags={setBlockedTags}/>}/>
            </div>
            <div className={cl.Container}>
                <div className={cl.SectionName}>{t("profile.advancedSettings.additional.title")}</div>
                <Setting secondRow={ authStore.deletedAt ? <RestoreAccountBtn/> : <DeleteAccountBtn/> }/>
            </div>
            { isLoading && <LoaderPage/> }
        </>
    );
}

export default observer(AdvancedSettings);

const DeleteAccountBtn = observer(() => {
    const { t } = useTranslation();
    const { authStore } = useContext(Context);
    const [isModal, setIsModal] = useState(false);
    const [acceptDeletion, isPending, error] = useFetching(async () => {
        if (isPending) {
            return
        }
        const data = await MemeDevService.deleteAccount();
        authStore.setDeletedAt(data.deleted_at);
        const msg = t("profile.advancedSettings.additional.accountDeletionMsg")
            .replace("%v", "14");
        toast.success(msg, {autoClose: 2000});
    });
    useEffect(() => {
        if (error) {
            toast.error(i18n.t("ui.defErrMsg"));
        }
    }, [error]);

    return (
        <>
            <div className={cl.AccountBtn + " " + cl.delete}
                 onClick={() => setIsModal(true)}>
                { t("profile.advancedSettings.additional.deleteAccount") }
            </div>
            <div className={cl.SettingsModal + ` ${isModal ? cl.active : ""}`}
                 onMouseDown={() => setIsModal(false)}>
                <div className={cl.SettingsModalContent + " " + cl.accountDeletionSubmit}
                     onMouseDown={(e) => e.stopPropagation()}>
                    <div className={cl.AccountDeletionSubmitTitle}>
                        { t("profile.advancedSettings.additional.deleteAccountAttentionTitle") }
                    </div>
                    <div className={cl.AccountDeletionSubmitDesc}>
                        { t("profile.advancedSettings.additional.deleteAccountAttentionDesc") }
                    </div>
                    <div className={cl.AccountDeletionSubmitBtn}
                         onClick={() => acceptDeletion({})}>
                        { t("profile.advancedSettings.additional.acceptAccountDeletion") }
                    </div>
                </div>
            </div>
        </>

    );
})

const RestoreAccountBtn = observer(() => {
    const { t } = useTranslation();
    const { authStore } = useContext(Context);
    const [restore, isPending, error] = useFetching(async () => {
        if (isPending) {
            return
        }
        await MemeDevService.restoreAccount();
        authStore.setDeletedAt(null);
        toast.success(i18n.t("profile.advancedSettings.additional.accountDeletionCanceledMsg"), {autoClose: 2000});
    });
    useEffect(() => {
        if (error) {
            toast.error(i18n.t("ui.defErrMsg"));
        }
    }, [error]);
    return (
        <div className={cl.AccountBtn + " " + cl.restore}
             onClick={() => restore({})}>
            { t("profile.advancedSettings.additional.restoreAccount") }
        </div>
    );
})


interface BlockedTagsProps {
    blockedTags: string[],
    setBlockedTags(bts: string[]): void,
}

interface BlockedTagProps {
    blockedTag: string,
    unblockTag(data: UnblockTagInfo): void,
}

function BlockedTagsTitle({blockedTags, setBlockedTags}: BlockedTagsProps) {
    const { t } = useTranslation();
    const [amnestyTags, isPending, error] = useFetching(async () => {
        if (isPending) {
            return
        }
        await TagService.unblockAllTags();
        setBlockedTags([]);
    });
    useEffect(() => {
        if (error) {
            toast.error(i18n.t("ui.defErrMsg"));
        }
    }, [error]);


    return (
        <>
            <div className={cl.SettingRowUnclickable}>{t("profile.advancedSettings.blackList.blockedTagsName")}</div>
            { blockedTags.length > 0 &&
                <AmnestyBtn callback={() => amnestyTags({})}
                            description={t("profile.advancedSettings.blackList.clearBlockedTagsSubmitDesc")}/>
            }
        </>
    );
}

const BlockedTags = observer(({blockedTags, setBlockedTags}: BlockedTagsProps) => {
    const { t } = useTranslation();
    const { authStore } = useContext(Context);
    const [fetchBlockedTags, isFetching, errorFetching] = useFetching(async () => {
        const blockedTagsInfo = await TagService.getBlockedTags();
        setBlockedTags(blockedTagsInfo.tags);
    });
    useEffect(() => {
        if (authStore.isInited && authStore.isAuth){
            fetchBlockedTags({});
        }
    }, [authStore.isInited])

    const [unblockTag, isPending, errorUnblock] = useFetching(async (data: UnblockTagInfo) => {
        if (isPending) {
            return;
        }
        const tag = data.tag;
        setBlockedTags(blockedTags.filter(value => tag !== value));
        await TagService.unblockTag(tag);
    });

    useEffect(() => {
        if (errorFetching || errorUnblock) {
            toast.error(i18n.t("ui.defErrMsg"));
        }
    }, [errorFetching, errorUnblock]);

    function BlockedTag({blockedTag, unblockTag}: BlockedTagProps) {
        return (
            <div className={cl.BlockedTagContainer}
                 title={t("ui.tag.clickToUnblock")}
                 onClick={() => unblockTag({tag: blockedTag})}>
                { blockedTag }
            </div>
        );
    }

    return (
        <div className={cl.BlockedTagsContainer}>
            { blockedTags.map(blockedTag =>
                <BlockedTag key={blockedTag}
                            blockedTag={blockedTag}
                            unblockTag={unblockTag}/>)
            }
            { (!isFetching && blockedTags.length === 0) &&
                t("profile.advancedSettings.blackList.noBlockedTags") }
        </div>
    );
})

interface BlockedMemeDevsProps {
    blockedMemeDevs: IMemeDev[],
    setBlockedMemeDevs(mds: IMemeDev[]): void,
}

function BlockedMemeDevsTitle({blockedMemeDevs, setBlockedMemeDevs}: BlockedMemeDevsProps) {
    const { t } = useTranslation();
    const [amnestyMemeDevs, isPending, error] = useFetching(async () => {
        if (isPending) {
            return
        }
        await MemeDevService.unblockAllMemeDevs();
        setBlockedMemeDevs([]);
    });
    useEffect(() => {
        if (error) {
            toast.error(i18n.t("ui.defErrMsg"));
        }
    }, [error]);


    return (
        <>
            <div className={cl.SettingRowUnclickable}>{t("profile.advancedSettings.blackList.blockedMemeDevsName")}</div>
            { blockedMemeDevs.length > 0 &&
                <AmnestyBtn callback={() => amnestyMemeDevs({})}
                            description={t("profile.advancedSettings.blackList.clearBlockedMemeDevsSubmitDesc")}/>
            }
        </>
    );
}

interface BlockedMemeDevsProps {
    blockedMemeDevs: IMemeDev[],
    setBlockedMemeDevs(mds: IMemeDev[]): void,
}

interface BlockedMemeDevProps {
    blockedMemeDev: IMemeDev,
    unblockMemeDev(data: UnblockMemeDevInfo): void,
}

const BlockedMemeDevs = observer(({blockedMemeDevs, setBlockedMemeDevs} : BlockedMemeDevsProps) => {
    const { t } = useTranslation();
    const { authStore } = useContext(Context);
    const [fetchBlockedMemeDevs, isFetching, errorFetching] = useFetching(async () => {
        const blockedMemeDevsRes = await MemeDevService.getBlockedMemeDevs();
        setBlockedMemeDevs(blockedMemeDevsRes);
    });
    useEffect(() => {
        if (authStore.isInited && authStore.isAuth){
            fetchBlockedMemeDevs({});
        }
    }, [authStore.isInited])
    const [unblockMemeDev, isPending, errorUnblock] = useFetching(async (data: UnblockMemeDevInfo) => {
        if (isPending) {
            return;
        }
        const id = data.id;
        setBlockedMemeDevs(blockedMemeDevs.filter(memeDev => id !== memeDev.id));
        await MemeDevService.unblockMemeDev(id);
    });

    useEffect(() => {
        if (errorFetching || errorUnblock) {
            toast.error(i18n.t("ui.defErrMsg"));
        }
    }, [errorFetching, errorUnblock]);

    const avatarSizeStyle : CSSProperties = {
        height: "4em",
        width: "4em",
        objectFit: "cover",
        borderRadius: "50%",
    }

    function BlockedMemeDev({blockedMemeDev, unblockMemeDev}: BlockedMemeDevProps) {
        return (
            <div className={cl.BlockedMemeDevContainer}
                 title={t("ui.memeDev.unblock")}
                 onClick={() => unblockMemeDev({id: blockedMemeDev.id})}>
                <ImageLoader url={ blockedMemeDev.avatar_url }
                             altUrl={def_avatar_img}
                             animStyle={avatarSizeStyle}
                             imgStyle={avatarSizeStyle}
                             alt="avatar"/>
                <div className={cl.BlockedMemeDevNickname}>{blockedMemeDev.nick_name}</div>
            </div>
        );
    }
    return (
        <div className={cl.BlockedMemeDevsContainer}>
            { blockedMemeDevs.map(blockedMemeDev =>
                <BlockedMemeDev key={blockedMemeDev.id}
                                blockedMemeDev={blockedMemeDev}
                                unblockMemeDev={unblockMemeDev}/>)
            }
            { (!isFetching && blockedMemeDevs.length === 0) &&
                t("profile.advancedSettings.blackList.noBlockedMemeDevs")
            }
        </div>
    );
})

interface AmnestyBtnProps {
    callback(): void,
    description: string,
}

function AmnestyBtn({callback, description}: AmnestyBtnProps) {
    const { t } = useTranslation();
    const [isModal, setIsModal] = useState(false);
    return (
        <div className={cl.AmnestyImgContainer}
             title={t("profile.advancedSettings.blackList.clearBlackList")}
             onClick={() => setIsModal(true)}>
            <img className={cl.AmnestyImg}
                 src={amnesty_img} alt={"amnesty"}/>
            <div className={cl.SettingsModal + ` ${isModal ? cl.active : ""}`}
                 onMouseDown={() => setIsModal(false)}>
                <div className={cl.SettingsModalContent + " " + cl.amnestySubmit}
                     onMouseDown={(e) => e.stopPropagation()}>
                    <div className={cl.AmnestySubmitTitle}>
                        { t("profile.advancedSettings.blackList.clearBlackList") }
                    </div>
                    <div className={cl.AmnestySubmitDesc}>
                        { description }
                    </div>
                    <div className={cl.AmnestySubmitBtn}
                         onClick={() => callback()}>
                        { t("ui.submit") }
                    </div>
                </div>
            </div>
        </div>
    );
}

function AccountVerification() {
    const { t } = useTranslation();
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
    const [deactivationTime, setDeactivationTime] = useState<number | null>(null);
    const [remainingTime, setRemainingTime] = useState<number | null>(null);

    const [sendEmail, isLoading, error] = useFetching(async () => {
        await AuthService.sendVerificationEmail();
        toast.success(t("profile.verification.settings.emailIsSent"), {autoClose: 1500});
    });

    async function handleClick() {
        const minute = 60000;
        setDeactivationTime(Date.now() + minute);
        setRemainingTime(minute / 1000);

        setTimeout(() => {
            setIsButtonDisabled(false);
            setDeactivationTime(null);
        }, minute);

        setIsButtonDisabled(true);
        await sendEmail({})
    }

    useEffect(() => {
        if (deactivationTime) {
            const interval = setInterval(() => {
                const remaining = Math.ceil((deactivationTime - Date.now()) / 1000);
                setRemainingTime(remaining);
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [deactivationTime]);

    useEffect(() => {
        if (error) {
            ErrorNotify({err: error});
        }
    }, [error]);

    return (
        <>
            {isButtonDisabled
                ? <Setting firstRow={<div className={cl.SettingRowUnclickable}>{`${t("profile.verification.settings.nextEmailRemaining")} ${remainingTime}`}</div>}
                           secondRow={t("profile.verification.bannerMsg")}
                           iconSrc={warning_img} iconAlt={"warning_img"}/>
                : <Setting firstRow={<div className={cl.SettingRowClickable}
                                          onClick={handleClick}>{t("profile.verification.settings.sendEmail")}</div>}
                           secondRow={t("profile.verification.bannerMsg")}
                           iconSrc={warning_img} iconAlt={"warning_img"}/>
            }
            {/*{ isLoading && <Loader/> }*/}
            {/*{ error && <ErrorMsg err={error}/> }*/}
            {/*{ isSent && <MessageModal visible={isSent} setVisible={setIsSent} msg={t("profile.verification.settings.emailIsSent")}/> }*/}
        </>
    );
}

function ChangePwdForm() {
    const { t } = useTranslation();
    const [pwdData, setPwdData] =
        useState({currentPwd: "", newPwd: "", submitPwd: ""})
    const [changePwd, isPending, error] = useFetching(async () => {
        if (pwdData.newPwd !== pwdData.submitPwd) {
            toast.error(t("authForm.signUp.error.pwdDontMatch"));
            return
        }
        await AuthService.changePassword({current_password: pwdData.currentPwd, new_password: pwdData.newPwd});
        toast.success(t("profile.advancedSettings.security.pwdUpdateOrRestoreSuccess"), {autoClose: 1000});
        setPwdData({currentPwd: "", newPwd: "", submitPwd: ""});
    })

    const [failFieldList, setFailFieldList] = useState<string[]>([]);
    useEffect(() => {
        if (error) {
            const props = {allMessages: true, validationObj: "user" as ValidationObject};
            const filedList = ErrorNotify({err: error, validationErrProps: props});
            setFailFieldList(filedList);
        }
    }, [error]);

    return (
        <form className={cl.FormContainer} onSubmit={(e) => {
            e.preventDefault();
            changePwd({});
        }}>
            <div className={cl.ChangePwdSectionTitle}>{ t("profile.advancedSettings.security.enterCurrentPwd") }</div>
            <PwdInput
                name={"memeBattleCurrentPassword"}
                value={pwdData.currentPwd}
                handleValueChange={e => setPwdData({...pwdData, currentPwd: e.target.value})}
                placeHolder={t("authForm.signUp.password")}
                inputStyle={failFieldList.includes("password") ? validationErrorStyle : {}}
                canShown={true}
            />
            <div className={cl.ChangePwdSectionTitle}>{ t("profile.advancedSettings.security.enterNewPwd") }</div>
            <PwdInput
                name={"memeBattleNewPassword"}
                value={pwdData.newPwd}
                handleValueChange={e => setPwdData({...pwdData, newPwd: e.target.value})}
                placeHolder={t("authForm.signUp.password")}
                inputStyle={failFieldList.includes("password") ? validationErrorStyle : {}}
                canShown={true}
            />
            <PwdInput
                name={"submitNewPassword"}
                value={pwdData.submitPwd}
                handleValueChange={e => setPwdData({...pwdData, submitPwd: e.target.value})}
                placeHolder={t("authForm.signUp.submitPassword")}
                inputStyle={pwdData.submitPwd !== pwdData.newPwd ? validationErrorStyle : {}}
                canShown={true}
            />
            <button type="submit"
                    className={cl.button}
                    style={(isPending) ? {backgroundColor: "lightgray"} : {}}
                    disabled={isPending}>
                {isPending ? t("ui.pending") : t("profile.advancedSettings.security.changePwd")}
            </button>
        </form>
    );
}

interface SettingProps {
    firstRow?: JSX.Element,
    firstRowAlt?: string, //view altValue if value undefined
    secondRow?: JSX.Element | string,
    iconSrc?: string,
    iconAlt?: string
}

function Setting(props: SettingProps) {
    return (
        <>
            <div className={cl.Separator}></div>
            <div className={cl.Setting}>
                { props.iconSrc &&
                    <div className={cl.SettingImgContainer}>
                        <img className={cl.SettingImg}
                             src={props.iconSrc} alt={props.iconAlt || ""}/>
                    </div>
                }
                <div className={`${cl.SettingElement}`}>
                    { (props.firstRow || props.firstRowAlt) &&
                        <div className={`${cl.SettingFirstRow}`}>
                            {props.firstRow ? props.firstRow : props.firstRowAlt}
                        </div>
                    }
                    { props.secondRow &&
                        <div className={cl.SettingSecondRow}>
                            {props.secondRow}
                        </div>
                    }
                </div>
            </div>
        </>
    );
}

// interface SettingSelectorProps extends SettingProps{
//     options: { code: string, name: string }[],
//     handleValueChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void
// }
//
// function SettingSelector(props: SettingSelectorProps) {
//     return (
//         <>
//             <div className={cl.Separator}></div>
//             <div className={cl.Setting}>
//                 <div className={cl.SettingImgContainer}>
//                     <img className={cl.SettingImg}
//                          src={props.iconSrc} alt={props.iconAlt}/>
//                 </div>
//                 <div className={cl.SettingElement}>
//                     <select className={cl.Selector}
//                             id={`selector_${props.description}`}
//                             value={props.value || props.altValue}
//                             onChange={(e) => props.handleValueChange ? props.handleValueChange(e) : () => {
//                             }}
//                     >
//                         <option value="" disabled>{props.altValue}</option>
//                         {props.options.map((opt) => (
//                             <option key={opt.code}
//                                     value={opt.code}>
//                                 {capitalizeFirstLetter(opt.name)}
//                             </option>
//                         ))}
//                     </select>
//                     <label htmlFor={`selector_${props.description}`}
//                            className={cl.SettingSecondRow}>
//                         {props.description}
//                     </label>
//                 </div>
//             </div>
//         </>
//     );
// }