// export const appMode : Mode = "dev";
//
// type Mode = "local" | "dev" | "prod"

export interface AppConfig {
    [key: string]: {
        SiteURL: string;
        ServerURL: string;
        SupportEmail: string;
    };
}

// const config: AppConfig = {
//     local: {
//         SiteURL: "http://localhost:3001",
//         ServerURL: "http://localhost",
//         SupportEmail: "support@meme-battle-dev.su",
//     },
//     dev: {
//         SiteURL: "https://meme-battle-dev.su",
//         ServerURL: "https://meme-battle-dev.su",
//         SupportEmail: "support@meme-battle-dev.su",
//     },
//     prod: {
//         SiteURL: "https://meme-battle.com",
//         ServerURL: "https://meme-battle.com",
//         SupportEmail: "support@meme-battle.com",
//     },
// };
//
// export default function getConfig(mode: Mode) {
//     return config[mode];
// }

export const config = {
    SiteURL: process.env.REACT_APP_SITE_URL || "",
    ServerURL: process.env.REACT_APP_SERVER_URL || "",
    SupportEmail: process.env.REACT_APP_SUPPORT_EMAIL || "",
    YMid: process.env.REACT_APP_YM_ID || 0,
    VKADSid: process.env.REACT_APP_VK_ADS_ID || 0,
    IOSStore: process.env.REACT_APP_IOS_STORE_LINK || "",
    AndroidStore: process.env.REACT_APP_ANDROID_STORE_LINK || "",
    HuaweiStore: process.env.REACT_APP_HUAWEI_STORE_LINK || "",
}