import React, {ChangeEvent, CSSProperties, useContext, useEffect, useRef, useState} from "react";
import cl from "./Navbar.module.css"
import {observer} from "mobx-react-lite";
import {NavLink} from "react-router-dom";
import region_img from "./images/profile/region.png";
import language_img from "./images/profile/language.png";
import planet_img from "./images/navbar/planet.png";
import app_store_img from "./images/navbar/App_Store_Logo.svg";
import play_market_img from "./images/navbar/Google_Play_Logo.png";
import app_gallery_img from "./images/navbar/App_Gallery_Logo.svg";
import youtube_img from "./images/navbar/youtube_logo.png";
import x_img from "./images/navbar/x_logo.svg";
import tg_img from "./images/navbar/tg_logo.png";
import boosty_img from "./images/navbar/boosty_icon.png";
import patreon_img from "./images/navbar/patreon_icon.png";
import tiktok_img from "./images/navbar/tiktok_logo.svg";
import social_media_img from "./images/navbar/social_media.png";
import {ContentPage} from "./Content";
import {useTranslation} from "react-i18next";
import {Context} from "../index";
import {LocaleSelector} from "./LoginRegister";
import {LyingSeparator} from "./ui/Separator";
import {uiLangs} from "../ui-translations/i18n";
import {TopMemesIcon} from "./images/navbar/TopMemesIcon";
import {TopMemeDevsIcon} from "./images/navbar/TopMemeDevsIcon";
import {BattleFieldIcon} from "./images/navbar/BattleFieldIcon";
import {BuilderIcon} from "./images/navbar/Builder";
import {capitalizeFirstLetter} from "../pkg/Strings";
import {useFetching} from "../hooks/useFetching";
import MemeDevService from "../services/MemeDevService";
import {sendMetric} from "../pkg/Metric";
import { config } from "../config";
import { battleFieldPath } from "../App";

interface NavbarProps {
    isNavbarNarrow: boolean,
    isBattlePageVisited: boolean
}

function Navbar({isNavbarNarrow, isBattlePageVisited}: NavbarProps) {
    const { t } = useTranslation();
    const {cache} = useContext(Context);
    const bottomLinkStyle: CSSProperties = {
        fontSize: "var(--smaller-font-size)",
        margin: 0,
    }
    const bottomLinkWideMarketLogoStyle: CSSProperties = {
        fontSize: "var(--smaller-font-size)",
        padding: "0",
        margin: "0.25em 0.6em 0.25em 0.6em",
    }
    const wideMarketLogoStyle: CSSProperties = {
        height: "2em",
        objectFit: "contain"
    }

    return (
        <div className={cl.Container} style={isNavbarNarrow ? {width: "var(--navbar-narrow-width)"} : {}}>
            <div className={cl.Navbar}>
                <div className={cl.Topbar}>
                    <AvailableLink path={`/hot-memes`}
                                   onClick={() => cache.setHotMemes([], 0, 0, 0, 0)}
                                   name={t("navbar.hotMemes")}
                                   svgIcon={"TopMemes"}
                                   isNavbarNarrow={isNavbarNarrow}/>
                    <AvailableLink path={`/top-meme-devs`}
                                   onClick={() => cache.setTopMemeDevs([], 0, 0, 0, 0)}
                                   name={t("navbar.topMemeDevs")}
                                   svgIcon={"TopMemeDevs"}
                                   isNavbarNarrow={isNavbarNarrow}/>
                    <AvailableLink path={battleFieldPath}
                                   isPulsing={!isBattlePageVisited}
                                   name={t("navbar.battleField")}
                                   svgIcon={"BattleField"}
                                   isNavbarNarrow={isNavbarNarrow}/>
                    <AvailableLink path={`/builder`}
                                   name={t("navbar.builder")}
                                   svgIcon={"Builder"}
                                   isNavbarNarrow={isNavbarNarrow}/>
                    {/*{ isAuthInited && !isAuth &&*/}
                        <>
                            <div style={{padding: "1vmin"}}>
                                <LyingSeparator/>
                            </div>
                            <div className={cl.LocaleSelectorTitle} style={isNavbarNarrow ? {fontSize: 0, padding: 0} : {}}>
                                { t("navbar.localeSettingsTitle") }
                            </div>
                            { isNavbarNarrow
                                ? <PopUpBtn btnImgSrc={language_img}
                                                    PopUpContent={
                                                        <>
                                                            <div className={cl.LocaleSelectorTitle}>{ t("navbar.localeSettingsTitle") }</div>
                                                            <LocaleSettings/>
                                                        </>
                                                    }/>
                                : <LocaleSettings/>
                            }
                        </>
                    {/*}*/}
                </div>
                <div className={cl.BottomBar}>
                    {/* <BlockedRow name={t("navbar.additional")} btnStyle={bottomLinkStyle} isNavbarNarrow={isNavbarNarrow}/> */}
                    <StoreAndMediaLinks isNavbarNarrow={isNavbarNarrow}/>
                    {/* { isNavbarNarrow
                        ? <PopUpBtn btnImgSrc={planet_img} PopUpContent={<UILangSelector/>}/>
                        : <UILangSelector/> } */}
                    <SupportLink isNavbarNarrow={isNavbarNarrow}/>
                </div>
            </div>
        </div>
    );
}

export default observer(Navbar);

interface StoreAndMediaLinksProps {
    isNavbarNarrow: boolean,
}

function StoreAndMediaLinks({isNavbarNarrow}: StoreAndMediaLinksProps) {
    const { i18n } = useTranslation();
    const tgLink = i18n.language === 'ru' ? 'https://t.me/meme_battle_ru' : 'https://t.me/meme_battle_en';

    return (
         <>
            { isNavbarNarrow
                ?
                <div className={cl.StoreAndMediaNarrowBarContainer}>
                    <NavLink to={config.IOSStore}
                             target={"_blank"}
                             onClick={async () => sendMetric("goal", "app_store_link")}
                             className={cl.MobileStoreLink}
                             style={{padding: "0.6em 0 0.6em 0"}}>
                        <img className={cl.StoreAndMediaImg}
                             src={app_store_img} alt="app_store_img"/>
                    </NavLink>
                    <NavLink to={config.AndroidStore}
                             target={"_blank"}
                             onClick={async () => sendMetric("goal", "play_market_link")}
                             className={cl.MobileStoreLink}
                             style={{padding: "0.6em 0 0.6em 0"}}>
                        <img className={cl.StoreAndMediaImg}
                             src={play_market_img} alt="play_market_img"/>
                    </NavLink>
                    <NavLink to={config.HuaweiStore}
                             target={"_blank"}
                             onClick={async () => sendMetric("goal", "app_gallery_link")}
                             className={cl.MobileStoreLink}
                             style={{padding: "0.6em 0 0.6em 0"}}>
                        <img className={cl.StoreAndMediaImg}
                             src={app_gallery_img} alt="app_gallery_img"/>
                    </NavLink>
                    <PopUpBtn
                        btnImgSrc={social_media_img}
                        PopUpContent={
                        <div className={cl.SocialMediaPopupContainer}>
                            <NavLink to={tgLink}
                                     target={"_blank"}
                                     className={cl.SocialMediaLink}>
                                <img className={cl.StoreAndMediaImg}
                                     src={tg_img} alt="tg_img"/>
                            </NavLink>
                            <NavLink to={"https://x.com/MemeBattleCom"}
                                     target={"_blank"}
                                     className={cl.SocialMediaLink}>
                                <img className={cl.StoreAndMediaImg}
                                     src={x_img} alt="x_img"/>
                            </NavLink>
                            <NavLink to={"https://boosty.to/meme_battle"}
                                     target={"_blank"}
                                     className={cl.SocialMediaLink}>
                                <img className={cl.StoreAndMediaImg}
                                     src={boosty_img} alt="boosty_img"/>
                            </NavLink>
                            <NavLink to={"https://patreon.com/MemeBattleCom"}
                                     target={"_blank"}
                                     className={cl.SocialMediaLink}>
                                <img className={cl.StoreAndMediaImg}
                                     src={patreon_img} alt="patreon_img"/>
                            </NavLink>
                        </div>
                        }/>
                </div>
                :
                <div className={cl.StoreAndMediaWideBarContainer}>
                    <div className={cl.StoreAndMediaFirstRow}>
                        <NavLink to={config.IOSStore}
                                target={"_blank"}
                                onClick={async () => sendMetric("goal", "app_store_link")}
                                className={cl.MobileStoreLink}>
                            <img className={cl.StoreAndMediaImg}
                                src={app_store_img} alt="app_store_img"/>
                        </NavLink>
                        <NavLink to={config.AndroidStore}
                                target={"_blank"}
                                onClick={async () => sendMetric("goal", "play_market_link")}
                                className={cl.MobileStoreLink}>
                            <img className={cl.StoreAndMediaImg}
                                src={play_market_img} alt="play_market_img"/>
                        </NavLink>
                        <NavLink to={config.HuaweiStore}
                                target={"_blank"}
                                onClick={async () => sendMetric("goal", "app_gallery_link")}
                                className={cl.MobileStoreLink}>
                            <img className={cl.StoreAndMediaImg}
                                src={app_gallery_img} alt="app_gallery_img"/>
                        </NavLink>
                    </div>
                    <div className={cl.StoreAndMediaSecondRow}>
                        <NavLink to={tgLink}
                                    target={"_blank"}
                                    className={cl.SocialMediaLink}>
                            <img className={cl.StoreAndMediaImg}
                                    src={tg_img} alt="tg_img"/>
                        </NavLink>
                        <NavLink to={"https://x.com/MemeBattleCom"}
                                    target={"_blank"}
                                    className={cl.SocialMediaLink}>
                            <img className={cl.StoreAndMediaImg}
                                    src={x_img} alt="x_img"/>
                        </NavLink>
                        <NavLink to={"https://boosty.to/meme_battle"}
                                    target={"_blank"}
                                    className={cl.SocialMediaLink}>
                            <img className={cl.StoreAndMediaImg}
                                    src={boosty_img} alt="boosty_img"/>
                        </NavLink>
                        <NavLink to={"https://patreon.com/MemeBattleCom"}
                                    target={"_blank"}
                                    className={cl.SocialMediaLink}>
                            <img className={cl.StoreAndMediaImg}
                                    src={patreon_img} alt="patreon_img"/>
                        </NavLink>
                    </div>
                </div>
            }
         </>
    );
}

interface BlockedRowProps {
    handleClick?(): void,
    name: string,
    svgIcon?: "TopMemes" | "TopMemeDev" | "BattleField" | "Builder",
    imgSrc?: string,
    btnStyle?: CSSProperties,
    isNavbarNarrow: boolean,
}

function BlockedRow(props: BlockedRowProps) {
    return (
        <div className={cl.Pending}
             onClick={props.handleClick}>
            <div className={cl.Row}
                 style={{paddingLeft: props.isNavbarNarrow ? 0 : "" , ...props.btnStyle}}>
                <div className={cl.core}
                     style={props.isNavbarNarrow ? {justifyContent: "center", gap: 0} : {}}>
                    { props.svgIcon === "TopMemes" && <TopMemesIcon color={"rgba(97, 97, 97, 255)"} size={"1em"}/> }
                    { props.imgSrc && <img style={{height: "1em"}} src={props.imgSrc} alt="button_img"/> }
                    { <div  className={cl.CoreName} style={{fontSize: props.isNavbarNarrow ? 0 : ""}}>{props.name}</div> }
                </div>
            </div>
        </div>
    );
}

interface AvailableLinkProps {
    path: ContentPage,
    isPulsing?: boolean,
    onClick?(): void,
    inNewTab?: boolean,
    name?: string,
    svgIcon?: "TopMemes" | "TopMemeDevs" | "BattleField" | "Builder",
    imgSrc?: string,
    imgStyle?: CSSProperties,
    btnStyle?: CSSProperties,
    shrinkBtnWidthToContentSize?: boolean,
    isNavbarNarrow: boolean,
}

function AvailableLink(props: AvailableLinkProps) {
    const [isSelected, setIsSelected] = useState(false);
    const [color, setColor] = useState("rgba(97, 97, 97, 255)");
    function handleStateChange(isActive: boolean) {
        if (isActive) {
            setIsSelected(true);
            return cl.Selected;
        } else {
            setIsSelected(false);
            return cl.Pending
        }
    }

    useEffect(() => {
        if (isSelected) {
            setColor("rgb(24, 118, 210)")
        } else {
            setColor("rgba(97, 97, 97, 255)")
        }
    }, [isSelected]);

    return (
        <NavLink className={({isActive}) => handleStateChange(isActive)}
                 onClick={props.onClick}
                 to={props.path}
                 target={props.inNewTab ? "_blank" : ""}
                 style={{display: props.shrinkBtnWidthToContentSize ? "flex" : ""}}>
            <div className={cl.Row + " " + cl.active + ` ${props.isPulsing ? cl.Pulsing : ""}`}
                 style={{paddingLeft: props.isNavbarNarrow ? 0 : "" , ...props.btnStyle}}>
                <div className={cl.core}
                     style={props.isNavbarNarrow ? {justifyContent: "center", gap: 0} : {}}>
                    { props.svgIcon === "TopMemes" && <TopMemesIcon color={color} size={"1em"}/> }
                    { props.svgIcon === "TopMemeDevs" && <TopMemeDevsIcon color={color} size={"1em"}/> }
                    { props.svgIcon === "BattleField" && <BattleFieldIcon color={color} size={"1em"}/> }
                    { props.svgIcon === "Builder" && <BuilderIcon color={color} size={"1em"}/> }
                    { props.imgSrc && <img style={props.imgStyle || {height: "1em", width: "1em", objectFit: "contain"}} src={props.imgSrc} alt="button_img"/> }
                    { props.name && <div className={cl.CoreName} 
                                         style={props.isNavbarNarrow ? {fontSize: 0, wordBreak: "keep-all"} : {}}>{props.name}</div> }
                </div>
            </div>
        </NavLink>
    );
}

const LocaleSettings = observer(() => {
    const {t} = useTranslation();
    const { authStore, localeStore} = useContext(Context);
    const colors: CSSProperties = {
        backgroundColor: "var(--container-color)",
    }
    const [setReg, isPendingSetReg, errorSetReg]
        = useFetching(async (e: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
        localeStore.setRegion(e.target.value);
        if (authStore.isAuth) {
            await MemeDevService.updateMemeDevRegion(e.target.value);
        }
    })
    const [setLang, isPendingSetLang, errorSetLang]
        = useFetching(async (e: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
        localeStore.setMemeLanguage(e.target.value);
        if (authStore.isAuth) {
            await MemeDevService.updateMemeDevLanguage(e.target.value);
        }
    })

    return (
        <>
            <div className={cl.LocaleSelectorContainer}>
                <img className={cl.LocaleSelectorImg}
                     src={language_img} alt={"language_img"}/>
                <LocaleSelector
                    name={"langSelector"}
                    value={localeStore.getMemeLanguage()}
                    handleValueChange={(e) => setLang(e)}
                    description={t("authForm.signUp.memesLang")}
                    options={localeStore.getLanguageList()}
                    inputStyle={colors}/>
            </div>
            {/* <div className={cl.LocaleSelectorContainer}>
                <img className={cl.LocaleSelectorImg}
                     src={region_img} alt={"region_img"}/>
                <LocaleSelector
                    name={"regSelector"}
                    value={localeStore.getRegion()}
                    handleValueChange={(e) => setReg(e)}
                    description={t("authForm.signUp.region")}
                    options={localeStore.getRegionList()}
                    inputStyle={colors}
                    showAttentionUnsupported={true}/>
            </div> */}
        </>
    );
})

function UILangSelector() {
    const { i18n } = useTranslation();

    return (
        <div className={cl.UILangContainer}>
            <img className={cl.UILangImg}
                 src={planet_img} alt={"planet_img"}/>
            <label className={cl.UILangLabel}
                   htmlFor={"uiLang"}>
                <select className={cl.UILangSelector}
                        name={"uiLang"}
                        id={"uiLang"}
                        value={i18n.language}
                        onChange={(e) => i18n.changeLanguage(e.target.value)}
                >
                    {Object.keys(uiLangs).map((lang: string, index: number) => (
                        <option
                            className={cl.UILangOption}
                            key={lang}
                            value={lang}
                        >{ capitalizeFirstLetter(uiLangs[lang].name) }
                        </option>
                    ))}
                </select>
            </label>
        </div>
    );
}

interface PopUpBrnProps {
    btnImgSrc: string,
    PopUpContent: JSX.Element,
}

function PopUpBtn({btnImgSrc, PopUpContent}: PopUpBrnProps) {
    const [isOpen, setIsOpen] = useState(false);
    const popUpRef = useRef<HTMLDivElement | null>(null);

    function handleClickOutside(e: MouseEvent) {
        if (popUpRef.current && !popUpRef.current.contains(e.target as Node)) {
            setIsOpen(false);
        }
    }
    function handleClickBtn() {
        setIsOpen(true);
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    return (
        <div className={cl.Row + " " + cl.active}
             style={{margin: 0, justifyContent: "center", paddingLeft: 0}}
             onClick={handleClickBtn}
             ref={popUpRef}>
            <div className={cl.core}
                 style={{justifyContent: "center"}}>
                <img className={cl.UILangImg}
                     src={btnImgSrc} alt={"button_img"}/>
            </div>
            { isOpen &&
                <div className={cl.PopUpContainer}>
                    { PopUpContent }
                </div>
            }
        </div>
    );
}

interface SupportLinkProps {
    isNavbarNarrow?: boolean
}

function SupportLink(props: SupportLinkProps) {
    const { t } = useTranslation();
    return (
        <>
            {/*<div style={{fontWeight: "normal", textAlign: "left", color: "var(--std-text-color)", fontSize: "0.80em", paddingLeft: "0.6em", paddingTop: "0.6em"}}>Связаться с поддержкой:</div>*/}
            <div className={cl.Row}
                 style={{paddingLeft: props.isNavbarNarrow ? 0 : ""}}>
                <div className={cl.SupportEmail}
                     style={ props.isNavbarNarrow ? {justifyContent: "center"} : {justifyContent: "left"} }>
                    <NavLink className={cl.SupportLink}
                             style={props.isNavbarNarrow ? {textDecoration: "none"} : {textDecoration: "underline"}}
                             to={"/support"}>
                        { props.isNavbarNarrow ? "@" : t("navbar.support") }
                    </NavLink>
                </div>
            </div>
        </>
    );
}