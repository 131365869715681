import React, {CSSProperties, useContext, useEffect, useRef, useState} from "react";
import logo from "./images/logo_v6.png"
import cl from "./Header.module.css"
import {observer} from "mobx-react-lite";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import arrow_down_img from "./images/arrow_down.png";
import settings_img from "./images/profile/gear.png";
import warning_img from "./images/profile/warning.png";
import logout_img from "./images/profile/logout.png";
import def_avatar_img from "./images/profile/default-avatar.png";
import {Context} from "../index";
import ImageLoader from "./ui/ImageLoader";
import {useTranslation} from "react-i18next";
import {ErrorNotify} from "./ui/ErrorPage";
import {useFetching} from "../hooks/useFetching";
import {daysLeftUntilTimestamp} from "../pkg/Day";
import {isMobile} from "react-device-detect";

interface HeaderProps {
    isAuth: boolean,
    setAuthModal(bool: boolean) : void
}

function Header({isAuth, setAuthModal}: HeaderProps) {
    const { authStore } = useContext(Context);
    return (
        <div>
            <div className={cl.FrameBackground}></div>
            <div className={cl.Frame} style={isMobile ? {width: "100%"} : {width: "calc(100vw - var(--scroll-width))"}}>
                <div className={cl.FramePanelContainer}>
                    <div className={cl.FramePanelLeft}>
                        {/*<div className={`${cl.ImgContainer} ${cl.Logo}`}>*/}
                        {/*    <img src={logo} style={imgStyle} alt="logo"/>*/}
                        {/*</div>*/}
                        <div className={cl.LogoContainer}>
                            <NavLink style={{color: "white", textDecoration: "none"}}
                                     to="">
                                <img className={cl.Logo} src={logo} alt="logo"/>
                            </NavLink>
                            <div className={cl.BetaMark}>
                                Beta
                            </div>
                        </div>
                    </div>
                    <div className={cl.FramePanelRight}>
                        <ProfileBtn setAuthModal={setAuthModal}/>
                        { authStore.deletedAt &&
                            <AccountDeletionBanner deletedAtUnixTime={authStore.deletedAt}/>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default observer(Header);

interface AccountDeletionBannerProps {
    deletedAtUnixTime: number
}

function AccountDeletionBanner({deletedAtUnixTime}: AccountDeletionBannerProps) {
    const { t } = useTranslation();
    const msg = t("profile.advancedSettings.additional.accountDeletionMsg")
        .replace("%v", daysLeftUntilTimestamp(deletedAtUnixTime).toString());
    return (
        <div className={cl.AccountDeletionBannerContainer}>
            <NavLink to={`/settings`}>
                <div className={cl.AccountDeletionBanner}>
                    <img className={cl.AccountDeletionBannerImg}
                         src={warning_img} alt={"warning"}/>
                    <div className={cl.AccountDeletionBannerMsg}>{ msg }</div>
                </div>
            </NavLink>
        </div>
    );
}

interface ProfileBtnProps {
    setAuthModal(b: boolean): void
}

const ProfileBtn = observer(({setAuthModal}: ProfileBtnProps) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const { t } = useTranslation();
    const {authStore} = useContext(Context);
    const isAuth = authStore.isAuth;
    const user = authStore.getUser();
    const popupRef = useRef<HTMLDivElement>(null);
    const location = useLocation();

    function handleBtnClick() {
        isAuth ? setIsPopupOpen(!isPopupOpen) : setAuthModal(true)
    }

    function handleClickOutsidePopUp (event: MouseEvent) {
        if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
            setIsPopupOpen(false);
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutsidePopUp);

        return () => {
            document.removeEventListener('mousedown', handleClickOutsidePopUp);
        };
    }, []);

    const imgStyle: CSSProperties = {
        height: "100%",
        width: "100%",
        borderRadius: "50%",
        objectFit: "cover",
    }

    return (
        <div className={cl.ProfileBtnContainer}>
            <div className={cl.ProfilePopUpBtn}
                 onClick={handleBtnClick}
                 ref={popupRef}>
                <div>{isAuth && user ? user.nick_name : t("authForm.login.title")}</div>
                <div className={cl.ProfileMenu}>
                    {isPopupOpen && <ProfilePopUp/>}
                    {(authStore.isAuth && !authStore.isVerified && location.pathname !== "/settings") &&
                        <div onClick={e => e.stopPropagation()}>
                            <VerificationBanner/>
                        </div>
                    }
                </div>
                <div className={`${cl.ProfileImgContainer}`}>
                    <ImageLoader key={`${user && user.avatar_url}`} // for update avatar if user changes avatar
                                 url={user ? user.avatar_url : ""}
                                 altUrl={def_avatar_img}
                                 imgStyle={imgStyle}
                                 animStyle={imgStyle}
                                 alt={"avatar_img"}/>
                </div>
                <img style={{width: "1em", marginTop: "0.2em"}}
                     src={arrow_down_img} alt="arrow_down"/>
            </div>
        </div>
    );
})

interface VerificationBannerProps {

}

const VerificationBanner = observer( (props: VerificationBannerProps) => {
    const { t } = useTranslation();
    const textStyle: CSSProperties = {fontSize: "0.8em", color: "white"};
    const textContainerStyle: CSSProperties = {paddingTop: "0.5vmin", paddingBottom: "0.5vmin"};
    return (
        <div className={cl.VerificationBanner}>
            <NavLink to={`/settings`}>
                <ProfilePopupSelectBtn name={t("profile.verification.bannerMsg")}
                                       textStyle={textStyle}
                                       containerStyle={{...textContainerStyle, cursor: "default"}}
                                       imgSrc={warning_img}/>
                <ProfilePopupSelectBtn name={t("profile.verification.bannerBtn")}
                                       textStyle={textStyle}
                                       containerStyle={textContainerStyle}
                                       imgSrc={settings_img}/>
            </NavLink>
        </div>
    );
})

function ProfilePopUp() {
    const {authStore} = useContext(Context);
    const user = authStore.getUser();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [logout, isPending, error] = useFetching(async () => {
        await authStore.logout(false);
        navigate("/");
    });

    useEffect(() => {
        if (error) {
            ErrorNotify({err:error});
        }
    }, [error]);

    return (
        <div className={cl.ProfilePopUp}>
            <NavLink to={`/meme-devs/${user?.id}`}>
                <ProfilePopupSelectBtn name={t("profile.popUp.myProfile")} imgSrc={def_avatar_img}/>
            </NavLink>
            <NavLink to={`/settings`}>
                <ProfilePopupSelectBtn name={t("profile.popUp.settings")} imgSrc={settings_img}/>
            </NavLink>
            <div onClick={() => logout({})}>
                <ProfilePopupSelectBtn name={t("profile.popUp.logout")} imgSrc={logout_img}/>
            </div>
        </div>
    );
}

interface ProfilePopupSelectBtnProps {
    name: string,
    textStyle?: CSSProperties,
    containerStyle?: CSSProperties,
    imgSrc: string,
}

function ProfilePopupSelectBtn(props: ProfilePopupSelectBtnProps) {
    return (
        <div className={cl.ProfilePopUpSelectBtn}
             style={props.containerStyle}>
            <div className={cl.ProfilePopUpSelectBtnImgContainer}>
                <img className={cl.ProfilePopUpSelectBtnImg} src={props.imgSrc} alt="button_img"/>
            </div>
            <div style={{marginLeft: "1.5vmin", ...props.textStyle}}>{props.name}</div>
        </div>
    );
}

