export function capitalizeFirstLetter(str: string) {
    if (str.length === 0) {
        return str
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function djbHash32pos(str: string): number {
    let hash = 5381;
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) + hash + char; // hash * 33 + char
    }
    return hash >>> 0; // Convert to unsigned 32-bit integer
}

export function djbHash(str: string): number {
    let hash = 5381;
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) + hash + char; // hash * 33 + char
    }
    return hash;
}