import React, {useContext, useEffect, useState} from 'react';
import Header from "./components/Header"
import cl from "./App.module.css";
import Content from "./components/Content";
import {Context} from "./index";
import {observer} from "mobx-react-lite";
import Navbar from "./components/Navbar";
import LoginRegister from "./components/LoginRegister";
import {Route, Routes, useLocation, useNavigate, useParams} from "react-router-dom";
import TopMemes, {DuncanChestMemes, SingleMemeWrapperWithIdInUrl} from "./components/content/Memes/Memes";
import TopMemeDevs from "./components/content/TopMemeDevs/TopMemeDevs";
import BattleField from "./components/content/BattleField/BattleField";
import Builder from "./components/content/Builder/Builder";
import Profile from "./components/content/Profile/Profile";
import AdvancedSettings from "./components/content/Profile/ProfileSettings";
import {Privacy, TermsOfUse} from "./components/content/UserAgreement/UserAgreement";
import Verification from "./additional-pages/Verification";
import ErrorPage from "./components/ui/ErrorPage";
import {ServerErrorImpl} from "./views/Error";
import {isMobile} from "react-device-detect";
import {Toast} from "./components/ui/Toast";
import RestorePassword from "./additional-pages/RestorePassword";
import RestorePasswordRequest from "./components/content/RestorePasswordRequest/RestorePasswordRequest";
import getBrowserFingerprint from "get-browser-fingerprint";
import {
    CookieAttention,
    hasUserReadCookieAttentionKey,
    hasUserReadMobileMarketNoticeKey, MobileMarketNotice, PromoBanner
} from "./components/ui/MainBanners";
import {Support} from "./components/content/Support/Support";
import {config} from "./config";
import {sendMetric} from "./pkg/Metric";
import {fetchAndActivate} from "firebase/remote-config";
import {remoteConfig} from "./firebaseConfig";

export let fingerprint = getBrowserFingerprint();

export const battleFieldPath = "/battle-field"

function App() {
    const {authStore, localeStore} = useContext(Context);
    const [authModal, setAuthModal] = useState(false);
    const [isRegister, setIsRegister] = useState(false);
    const [layoutSettings, setLayoutSettings] = useState({
        isNavbarNarrow: isMobile ? true : false,
        isContentWide: false,
    });
    const key = localeStore.memeLanguage;

    useEffect(() => {
        authStore.checkAuth();
        // @ts-ignore
        window.ym(config.YMid, "init", {
            clickmap:true,
            trackLinks:true,
            accurateTrackBounce:true,
            webvisor:false
        });
        // @ts-ignore
        window._tmr.push({id: config.VKADSid, type: "pageView", start: (new Date()).getTime()});

        fetchAndActivate(remoteConfig)
            .then(() => {
                console.log("rc fetched");
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const location = useLocation();
    const [prevLocation, setPrevLocation] = useState<string | null>(null);

    useEffect(() => {
        if (prevLocation !== location.pathname) {
            sendMetric("url", location.pathname);
        }
        setPrevLocation(location.pathname);
    }, [location.pathname]);

    return (
        <div className={cl.App}>
            <Routes>
                <Route path={``} element={<MainApp isAuthInited={authStore.isInited}
                                                   isAuth={authStore.isAuth}
                                                   authModal={authModal}
                                                   setAuthModal={setAuthModal}
                                                   isRegister={isRegister}
                                                   setIsRegister={setIsRegister}
                                                   layoutSettings={layoutSettings}/>}>
                    <Route path={``} element={<DuncanChestMemes key={key}/>}/>
                    { [`/top-memes`, `/hot-memes`].map((path, index) =>
                        <Route key={path}
                               path={path}
                               element={<TopMemes key={key + authStore.isAuth + index}
                                                  setAuthModal={setAuthModal}/>}/>)
                    }
                    <Route path={`/top-meme-devs`} element={<TopMemeDevs/>}/>
                    <Route path={battleFieldPath} element={<BattleField key={authStore.isAuth + key}
                                                                        isAuth={authStore.isAuth}
                                                                        setAuthModal={setAuthModal}
                                                                        setIsRegister={setIsRegister}
                                                                        setIsContentWide={(b) => {
                                                                            if (!isMobile) {
                                                                                setLayoutSettings({...layoutSettings, isContentWide: b})
                                                                            }
                                                                        }}/>}/>
                    <Route path={`/builder`} element={<Builder setAuthModal={setAuthModal}/>}/>
                    { [`/top-memes/memes/:id`, `/hot-memes/memes/:id`].map((path, index) =>
                        <Route key={path}
                               path={path}
                               element={<TopMemes key={key + index}
                                                  setAuthModal={setAuthModal}
                                                  modalMemeIdInUrl={true}/>}
                        />)
                    }
                    <Route path={`/memes/:id/*`} element={<SingleMemeWrapperWithIdInUrl key={key}/>}/>

                    <Route path={`/meme-devs/:id/*`} element={<Profile key={key}/>}/>
                    <Route path={`/settings/*`} element={<AdvancedSettings/>}/>
                    <Route path={`/restore-password-request`} element={<RestorePasswordRequest/>}/>

                    <Route path={`/referral/:nickname`} element={<Referral setAuthModal={setAuthModal}/>}/>

                    <Route path={`/support`} element={<Support key={key}/>}/>
                    <Route path={`/privacy`} element={<Privacy key={key}/>}/>
                    <Route path={`/terms-of-use`} element={<TermsOfUse key={key}/>}/>

                    <Route path={`/*`} element={<ErrorPage err={new ServerErrorImpl("not found", 404)}/>}/>
                </Route>
                <Route path={`/verification/:id/:token`} element={<Verification/>}/>
                <Route path={`/restore-password/:id/:token`} element={<RestorePassword/>}/>
            </Routes>
            <Toast/>
        </div>
    );
}

export default observer(App);

interface ReferralProps {
    setAuthModal(b: boolean): void
}

function Referral({setAuthModal}: ReferralProps) {
    const navigate = useNavigate();
    const params = useParams();
    if (params.nickname) {
        localStorage.setItem("referral", params.nickname);
        setAuthModal(true);
    }
    navigate("/hot-memes");

    return (<></>);
}

interface MainAppProps {
    isAuthInited: boolean,
    isAuth: boolean,
    authModal: boolean,
    setAuthModal(b: boolean): void,
    isRegister: boolean,
    setIsRegister(bool: boolean) : void,
    layoutSettings: {
        isNavbarNarrow: boolean,
        isContentWide: boolean,
    },
}

function MainApp({isAuth, isAuthInited, authModal, setAuthModal, isRegister, setIsRegister, layoutSettings}: MainAppProps) { // <Outlet/> is inside <Content/> component
    if (isMobile) {
        const root = document.documentElement;
        root.style.setProperty('--large-font-size', '18px');
        root.style.setProperty('--medium-font-size', '16px');
        root.style.setProperty('--small-font-size', '16px');
        root.style.setProperty('--smaller-font-size', '14px');
    }

    const location = useLocation();
    const [isBattlePage, setIsBattlePage] = useState(false);
    const [isBattlePageVisited, setIsBattlePageVisited] = useState(!!localStorage.getItem("battlePageVisited"));
    useEffect(() => {
        if (location.pathname.includes(battleFieldPath)) {
            setIsBattlePage(true);
            setIsBattlePageVisited(true);
            localStorage.setItem("battlePageVisited", "true")
        } else {
            setIsBattlePage(false);
        }
    }, [location.pathname]);

    const [isCookieRead, setIsCookieRead] = useState(!!localStorage.getItem(hasUserReadCookieAttentionKey));
    const [isMobileMarketNoticeRead, setIsMobileMarketNoticeRead] = useState(!!localStorage.getItem(hasUserReadMobileMarketNoticeKey));

    return (
        <>
            <Header isAuth={isAuth} setAuthModal={setAuthModal}/>
            <LoginRegister visible={authModal} setIsVisible={setAuthModal} isRegister={isRegister} setIsRegister={setIsRegister}/>
            <div className={cl.background}>
                <div className={cl.layout}
                     style={layoutSettings.isContentWide ? {maxWidth: "var(--layout-wide-max-width)"} : {}}>
                    <Navbar isNavbarNarrow={layoutSettings.isNavbarNarrow} 
                            isBattlePageVisited={isBattlePageVisited}/>
                    <Content isNavbarNarrow={layoutSettings.isNavbarNarrow}/>
                </div>
                <div className={cl.BannerContainer}
                     style={isMobile ? {width: "85%", left: "50%"} : {}}>
                    { !isBattlePage && isCookieRead &&
                        <PromoBanner setAuthModal={setAuthModal} setIsRegister={setIsRegister}/>
                    }
                    { !isMobileMarketNoticeRead && isMobile &&
                        <MobileMarketNotice setIsMobileMarketNoticeRead={setIsMobileMarketNoticeRead}/>
                    }
                    { !isCookieRead &&
                        <CookieAttention setIsCookieRead={setIsCookieRead}/>
                    }
                </div>
            </div>
        </>
    );
}